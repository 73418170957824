import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, AlertColor, AlertTitle, Button, styled, Typography } from '@mui/material'
import { UserNotification } from './notification.types'
import { formatDateTime } from 'utils/date-utils'
import { Delete } from '@mui/icons-material'
import { CreateEditNotificationDialog } from './CreateEditNotificationDialog'
import { ConfirmDialog } from '../dialogs/confirm-dialog'
import { useDeleteNotificationMutation } from './userNotifications.service'

type NotificationsListProps = {
    notifications: UserNotification[]
    count: number
}

export const NotificationsList: React.FC<NotificationsListProps> = ({ count, notifications }) => {
    const { t } = useTranslation()
    const [deleteNotification] = useDeleteNotificationMutation()

    const [notificationIdToEdit, setNotificationIdToEdit] = React.useState<string | null>(null)

    const handleDeleteNotification = async (id: string) => {
        await deleteNotification({ id }).unwrap()
    }

    return (
        <>
            <Container>
                {count === 0 && (
                    <Typography variant="body1" mb={3}>
                        {t('ADMIN_NOTIFICATIONS_EMPTY', 'Ingen aktive statusmeldinger')}
                    </Typography>
                )}
                {count !== 0 && (
                    <ListContainer>
                        {notifications.map((notification) => (
                            <ListItemContainer key={notification.id}>
                                <Alert severity={notification.variant.toLowerCase() as AlertColor}>
                                    <AlertTitle sx={{ fontWeight: 600 }}>{notification.title}</AlertTitle>
                                    {notification.message}
                                </Alert>

                                <NotificationDetailsContainer>
                                    <Typography variant="body2">{`${t(
                                        'NOTIFICATION_ADDED_AT',
                                        'Lagt til',
                                    )}: ${formatDateTime(notification.created)}`}</Typography>
                                    <Typography variant="body2" sx={{ ml: 4 }}>{`${t(
                                        'NOTIFICATION_DURATION_INTERVAL',
                                        'Varighet',
                                    )}: ${formatDateTime(notification.showFrom)} - ${
                                        notification.showTill
                                            ? formatDateTime(notification.showTill)
                                            : t('UNSPECIFIED_DATE', 'not specified')
                                    }`}</Typography>
                                    <Button
                                        sx={{ ml: 2 }}
                                        type="button"
                                        variant="outlined"
                                        size="small"
                                        onClick={() => setNotificationIdToEdit(notification.id)}
                                    >
                                        {t('NOTIFICATION_EDIT_BTN', 'Endre')}
                                    </Button>

                                    <ConfirmDialog
                                        key={'delete_confirm'}
                                        title={t('DELETE_NOTIFICATION_TITLE', 'Delete notification')}
                                        content={t(
                                            'DELETE_NOTIFICATION_CONTENT',
                                            'Are you sure you want to delete selected notification',
                                        )}
                                        onConfirm={() => handleDeleteNotification(notification.id)}
                                    >
                                        <Button startIcon={<Delete />} type="button" variant="outlined" size="small">
                                            {t('NOTIFICATION_DELETE_BTN', 'Avslutt statusmelding')}
                                        </Button>
                                    </ConfirmDialog>
                                </NotificationDetailsContainer>
                            </ListItemContainer>
                        ))}
                    </ListContainer>
                )}
            </Container>
            <CreateEditNotificationDialog
                isOpen={Boolean(notificationIdToEdit)}
                onClose={() => setNotificationIdToEdit(null)}
                notification={
                    notificationIdToEdit
                        ? notifications.find((notification) => notification.id === notificationIdToEdit)
                        : undefined
                }
            />
        </>
    )
}

const Container = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(3),
}))

const ListContainer = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(4),
}))

const ListItemContainer = styled('div')(({ theme }) => ({
    borderTop: '1px solid #bebebe',
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(4.5),
}))

const NotificationDetailsContainer = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(1.5),
    display: 'flex',
    gap: theme.spacing(3.5),
    flexWrap: 'wrap',
    alignItems: 'center',
}))
