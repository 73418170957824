/** @jsxImportSource @emotion/react */
import { getStandardRightActions, TopBar } from '../topbar/top-bar'
import { Grid } from '@mui/material'
import { BottomPageNavigation } from '../bottom-navigation/bottom-navigation'
import { KotiContainer } from '../general/layout/koti-container'
import { useTranslation } from 'react-i18next'
import { TopPageTabs } from '../general/tabs/top-page-tabs'
import { useEffect, useState } from 'react'
import { CompanyAdminInvoiceView } from './company-admin-invoice.view'
import { CompanySettings } from './settings/company-settings'
import { UsersView } from '../users/users.view'
import { useAuthContext } from '../auth/auth.slice'
import { CompanyAdminDepartmentsView } from './company-admin-departments.view'
import { useGetCompanyQuery } from '../../redux/apis/companies-api'
import { CompanyTabs } from '../companies/single-page/company.view'
import { useSearchParams } from 'react-router-dom'
import { CompanyApiTokens } from './api-tokens'
import { Config } from 'redux/config'

const isApiTokensFeatureOn = Config.enableCustomerApi

export enum CompanyAdminTabs {
    Invoice = 'invoice',
    CompanySettings = 'companySettings',
    Users = 'users',
    Departments = 'departments',
    ApiTokens = 'api-tokens',
}

export const CompanyAdminPage: React.FC = () => {
    const { t } = useTranslation()
    const context = useAuthContext()
    const companyId = context?.entityId ?? ''
    const [tab, setTab] = useState<string>()
    const [searchParams, setSearchParams] = useSearchParams({})

    const { data: company } = useGetCompanyQuery(companyId)
    useEffect(() => {
        const tabFromQuery = searchParams.get('tab') || CompanyTabs.Users
        setTab(tabFromQuery)
    }, [searchParams])

    const setTabs = (tabName: string) => {
        setTab(tabName)
        setSearchParams({ tab: tabName }, { replace: true })
    }
    if (!companyId) return null

    let tabs = [
        { value: CompanyAdminTabs.Invoice, label: t('TEXT_INVOICE', 'Faktura') },
        { value: CompanyAdminTabs.CompanySettings, label: t('TEXT_SETTINGS', 'Innstillinger') },
        { value: CompanyAdminTabs.Users, label: t('TEXT_USERS', 'Brukere') },
    ]
    if (company && company.parentId === null) {
        tabs = [...tabs, { value: CompanyAdminTabs.Departments, label: t('TEXT_DEPARTMENTS', 'Avdelinger') }]
    }
    if (company && company.config.apiAccess?.isPartner && isApiTokensFeatureOn) {
        tabs = [...tabs, { value: CompanyAdminTabs.ApiTokens, label: t('TEXT_API_TOKENS', 'API-tilgangstokener') }]
    }

    return (
        <div>
            <TopBar rightActions={getStandardRightActions()} />
            <KotiContainer>
                {tab && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TopPageTabs tabs={tabs} tab={tab} setTab={setTabs} title={t('TEXT_ADMIN', 'Admin')} />
                        </Grid>
                        <Grid item xs={12}>
                            {tab === CompanyAdminTabs.Invoice && <CompanyAdminInvoiceView company={company} />}

                            {tab === CompanyAdminTabs.Users && <UsersView companyId={companyId ?? ''} />}
                            {tab === CompanyAdminTabs.Departments && company && !company.parentId && (
                                <CompanyAdminDepartmentsView />
                            )}
                            {tab === CompanyAdminTabs.CompanySettings && <CompanySettings id={companyId ?? ''} />}
                            {tab === CompanyAdminTabs.ApiTokens && <CompanyApiTokens />}
                        </Grid>
                    </Grid>
                )}
            </KotiContainer>
            <BottomPageNavigation />
        </div>
    )
}
