import { Role } from 'components/auth/role'
import { PaginationParams } from '../models/pagination-params'

export const NotificationSeverities = {
    Error: 'Error',
    Info: 'Info',
    Success: 'Success',
} as const

export type NotificationVariant = (typeof NotificationSeverities)[keyof typeof NotificationSeverities]

export type UserNotification = {
    id: string
    title: string
    message: string
    showFrom: Date
    showTill: Date | null
    isDismissable: boolean
    variant: NotificationVariant
    created: Date
}

export type UserStorageNotification = UserNotification & { dismissed: boolean }

export type CreateNotificationRequest = Omit<UserNotification, 'id' | 'created'> & { forRoles: Role[] }

export type EditNotificationRequest = {
    id: string
    notification: CreateNotificationRequest
}

export type NotificationFilter = {
    dateFrom?: Date
    dateTo?: Date
    activeOnly: boolean
}

export type GetNotificationsRequest = PaginationParams<UserNotification, NotificationFilter>
