import React from 'react'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useAuthContext } from 'components/auth/auth.slice'
import { useDeleteAccessTokenMutation, useGetCompanyAccessTokensQuery } from 'redux/apis/access-tokens-api'
import { Box, Button, Paper, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { AccessTokenBase } from 'types/access-tokens/access-token'
import { Trans, useTranslation } from 'react-i18next'
import { DataGridNoRows } from 'components/general/table/dataGrid/DataGridNoRows'
import { formatDate, isDateNeverExpires } from 'utils/date-utils'
import ConfirmDialogV2 from 'components/general/dialogs/confirm-dialog-v2'
import { Add } from '@mui/icons-material'
import { CreateTokenDialog } from './create-token-dialog'
import { TokenKeyViewDialog } from './token-key-view-dialog'

export const CompanyApiTokens: React.FC = () => {
    const { t } = useTranslation()
    const authContext = useAuthContext()

    const [deleteToken, { isLoading: isDeleting }] = useDeleteAccessTokenMutation()
    const { data: accessTokens, isLoading } = useGetCompanyAccessTokensQuery(
        authContext?.entityId ? { companyId: authContext?.entityId } : skipToken,
    )

    const [selectedTokenIdToDelete, setSelectedTokenIdToDelete] = React.useState<string | null>(null)
    const [isCreateTokenDialogOpen, setIsCreateTokenDialogOpen] = React.useState(false)
    const [createdTokenKey, setCreatedTokenKey] = React.useState<string | null>(null)

    const selectedToken = accessTokens?.find((token) => token.id == selectedTokenIdToDelete)

    const columns: GridColDef<AccessTokenBase>[] = [
        {
            field: 'name',
            headerName: t('name'),
            flex: 1,
            editable: false,
            minWidth: 200,
        },
        {
            field: 'scopes',
            headerName: t('ACCESS_TOKEN_SCOPES', 'Scopes'),
            flex: 1,
            editable: false,
            minWidth: 550,
            valueGetter: (params) => params.row.scopes.join(', '),
        },
        {
            field: 'validUntil',
            headerName: t('ACCESS_TOKEN_EXPIRES_ON', 'Expires on'),
            flex: 1,
            editable: false,
            minWidth: 200,
            maxWidth: 220,
            valueGetter: (params) =>
                isDateNeverExpires(params.row.validUntil)
                    ? t('TEXT_NEVER', 'Never')
                    : formatDate(params.row.validUntil),
        },
        {
            type: 'actions',
            flex: 1,
            filterable: false,
            field: 'actions',
            minWidth: 100,
            maxWidth: 120,
            renderCell: (params) => (
                <Button variant="contained" color="error" onClick={() => setSelectedTokenIdToDelete(params.row.id)}>
                    {t('DELETE', 'Delete')}
                </Button>
            ),
        },
    ]

    const handleTokenCreation = (tokenKey: string) => {
        setIsCreateTokenDialogOpen(false)
        setCreatedTokenKey(tokenKey)
    }

    const handleTokenDelete = async () => {
        if (!selectedTokenIdToDelete) return

        try {
            if (!authContext?.entityId) throw new Error('User without company cant delete tokens')

            const copy = selectedTokenIdToDelete
            setSelectedTokenIdToDelete(null)

            deleteToken({ id: copy, companyId: authContext?.entityId }).unwrap()
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <>
            <Paper elevation={0} square>
                <Box padding={2}>
                    <Button
                        type="button"
                        color="accent"
                        variant="contained"
                        startIcon={<Add />}
                        onClick={() => setIsCreateTokenDialogOpen(true)}
                    >
                        {t('ADD_ACCESS_TOKEN', 'Add token')}
                    </Button>
                </Box>
                <Box sx={{ width: '100%', padding: 2 }}>
                    <DataGrid
                        rows={accessTokens || []}
                        density="comfortable"
                        rowCount={accessTokens?.length || 0}
                        columns={columns}
                        autoHeight
                        disableRowSelectionOnClick
                        disableColumnMenu
                        hideFooterSelectedRowCount
                        hideFooter
                        hideFooterPagination
                        slots={{
                            noRowsOverlay: DataGridNoRows,
                        }}
                        loading={isLoading}
                        keepNonExistentRowsSelected
                    />
                </Box>
            </Paper>
            <ConfirmDialogV2
                handleClose={() => setSelectedTokenIdToDelete(null)}
                open={Boolean(selectedTokenIdToDelete)}
                onConfirm={handleTokenDelete}
                content={
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        <Trans
                            i18nKey="CONFIRM_ACCESS_TOKEN_DELETE"
                            values={{
                                tokenName: selectedToken?.name || '',
                            }}
                            defaults="Do you want to delete <strong>{{tokenName}}</strong> token?"
                        />
                    </Typography>
                }
                isLoading={isDeleting}
            />
            {authContext?.entityId && (
                <CreateTokenDialog
                    isOpen={isCreateTokenDialogOpen}
                    onTokenCreate={handleTokenCreation}
                    onClose={() => setIsCreateTokenDialogOpen(false)}
                    companyId={authContext.entityId}
                />
            )}
            <TokenKeyViewDialog
                isOpen={Boolean(createdTokenKey)}
                createdKey={createdTokenKey}
                onClose={() => setCreatedTokenKey(null)}
            />
        </>
    )
}
