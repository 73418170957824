import { Field, Form } from 'react-final-form'
import { CubitTextFieldAdapter } from '../forms/cubit-text-field-adapter'
import { CreateNotificationRequest, NotificationSeverities } from './notification.types'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { useTranslation } from 'react-i18next'
import { CubitSelectAdapter } from '../forms/cubit-select-adapter'
import { CubitDatePickerAdapter } from '../forms/cubit-date-picker-adapter'
import { CubitTimePickerAdapter } from '../forms/cubit-timer-picker-adapter'
import { Box, Typography } from '@mui/material'
import { Dayjs } from 'dayjs'
import { Role } from 'components/auth/role'
import { CubitCheckboxAdapter } from '../forms/cubit-checkbox-adapter'

export type CreateNotificationForm = Omit<CreateNotificationRequest, 'showFrom' | 'showTill'> & {
    showFrom: Dayjs
    showFromTime: Dayjs
    showTill: Dayjs | null
    showTillTime: Dayjs | null
}

type NotificationFormProps = {
    formId: string
    onSubmit: (form: CreateNotificationForm) => void
    initialValues: any
}

export const NotificationForm: React.FC<NotificationFormProps> = ({ onSubmit, initialValues, formId }) => {
    const { t } = useTranslation()

    const variantOptions = Object.values(NotificationSeverities).map((variant) => ({
        label: t(variant),
        value: variant,
    }))
    const rolesOptions = Object.values(Role)
        .filter((r) => r !== Role.CubitAdmin)
        .map((role) => ({
            label: t(`role_${role}`),
            value: role,
        }))

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, errors, touched }) => {
                return (
                    <form onSubmit={handleSubmit} id={formId}>
                        <div>
                            <Field
                                name="variant"
                                width="100%"
                                component={CubitSelectAdapter}
                                valueIsObject={false}
                                options={variantOptions}
                                label={t('NOTIFICATION_VARIANT_INPUT_LABEL', 'Type')}
                                validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                                variant="standard"
                            />

                            <Field
                                name="title"
                                width="100%"
                                component={CubitTextFieldAdapter}
                                label={t('NOTIFICATION_TITLE_INPUT_LABEL', 'Tittel')}
                                validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                                variant="standard"
                            />

                            <Field
                                name="message"
                                width="100%"
                                component={CubitTextFieldAdapter}
                                label={t('NOTIFICATION_DESCRIPTION_INPUT_LABEL', 'Beskrivelse')}
                                validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                                variant="standard"
                            />

                            <Box display="flex">
                                <Field
                                    name="showFrom"
                                    margin={'dense'}
                                    component={CubitDatePickerAdapter}
                                    label={t('NOTIFICATION_SHOW_FROM_INPUT_LABEL', 'Fra')}
                                    validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                                    variant="standard"
                                />

                                <Field
                                    name="showFromTime"
                                    margin={'dense'}
                                    component={CubitTimePickerAdapter}
                                    label={t('NOTIFICATION_SHOW_FROM_TIME_INPUT_LABEL', 'Fra tidspunkt')}
                                    validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                                    variant="standard"
                                />
                            </Box>

                            <Box display="flex">
                                <Field
                                    name="showTill"
                                    margin={'dense'}
                                    component={CubitDatePickerAdapter}
                                    label={t('NOTIFICATION_SHOW_TILL_INPUT_LABEL', 'Til')}
                                    variant="standard"
                                />

                                <Field
                                    name="showTillTime"
                                    margin={'dense'}
                                    component={CubitTimePickerAdapter}
                                    label={t('NOTIFICATION_SHOW_TILL_TIME_INPUT_LABEL', 'Til tidspunkt')}
                                    variant="standard"
                                />
                            </Box>

                            <Typography variant="body2" mt={2}>
                                {t('TEXT_NOTIFICATION_FOR_ROLES', 'Show for these roles')}
                            </Typography>
                            <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap={2}>
                                {rolesOptions.map((role) => (
                                    <Field
                                        key={role.value}
                                        name="forRoles"
                                        label={role.label}
                                        component={CubitCheckboxAdapter}
                                        options={[role]}
                                        value={role.value}
                                        validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                                        multiple
                                    />
                                ))}
                            </Box>
                            {touched?.forRoles && errors?.forRoles && (
                                <Typography variant="caption" color="error.main">
                                    {errors.forRoles}
                                </Typography>
                            )}
                        </div>
                    </form>
                )
            }}
        />
    )
}
