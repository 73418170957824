import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import StyledContainedButton from 'components/styled/styled-contained-button'
import { CreateNotificationForm, NotificationForm } from './NotificationForm'
import { CreateNotificationRequest, NotificationSeverities, UserNotification } from './notification.types'
import dayjs from 'dayjs'
import { useCreateNotificationMutation, useEditNotificationMutation } from './userNotifications.service'

const FORM_ID = 'notification-form'

type CreateNotificationDialogProps = {
    isOpen: boolean
    onClose: () => void
    notification?: UserNotification
}

export const CreateEditNotificationDialog: React.FC<CreateNotificationDialogProps> = ({
    isOpen,
    onClose,
    notification,
}) => {
    const { t } = useTranslation()
    const [create, { isLoading: isCreating }] = useCreateNotificationMutation()
    const [edit, { isLoading: isEditing }] = useEditNotificationMutation()

    const isLoading = isCreating || isEditing
    const isPassed = notification?.showFrom ? new Date(notification.showFrom) < new Date() : false

    const handleSubmit = async (values: CreateNotificationForm) => {
        const { showFrom, showFromTime, showTillTime, showTill } = values

        const formattedFromDate = dayjs()
            .set('year', showFrom.year())
            .set('month', showFrom.month())
            .set('date', showFrom.date())
            .set('hour', showFromTime.hour())
            .set('minute', showFromTime.minute())
            .set('second', 0)
            .toDate()
        let formattedTillDate: Date | null = null

        if (showTill) {
            formattedTillDate = dayjs()
                .set('year', showTill.year())
                .set('month', showTill.month())
                .set('date', showTill.date())
                .set('hour', showTillTime ? showTillTime.hour() : 23)
                .set('minute', showTillTime ? showTillTime.minute() : 59)
                .set('second', showTillTime ? 0 : 59)
                .toDate()
        }

        const request: CreateNotificationRequest = {
            isDismissable: values.isDismissable,
            message: values.message,
            title: values.title,
            variant: values.variant,
            showFrom: formattedFromDate,
            showTill: formattedTillDate,
            forRoles: values.forRoles,
        }

        if (notification) {
            await edit({ id: notification.id, notification: request }).unwrap()
        } else {
            await create(request).unwrap()
        }

        onClose()
    }

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle sx={{ fontWeight: 'bold' }}>
                {notification
                    ? t('EDIT_NOTIFICATION_DIALOG_TITLE', 'Rediger statusmelding')
                    : t('CREATE_NOTIFICATION_DIALOG_TITLE', 'Legg til statusmelding')}
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    {t(
                        'CREATE_NOTIFICATION_DIALOG_DESCRIPTION',
                        'Statusmeldinger blir lagt til i toppen av søkesiden.',
                    )}
                </Typography>

                <NotificationForm
                    formId={FORM_ID}
                    initialValues={
                        notification
                            ? {
                                  ...notification,
                                  showFrom: dayjs(notification.showFrom),
                                  showTill: notification.showTill ? dayjs(notification.showTill) : null,
                                  showFromTime: dayjs(notification.showFrom), // will set the date but we are only interested in hh:mm part which is correct
                                  showTillTime: notification.showTill ? dayjs(notification.showTill) : null,
                              }
                            : {
                                  variant: NotificationSeverities.Info,
                                  isDismissable: true,
                                  showFrom: dayjs(),
                                  showFromTime: dayjs(),
                                  forRoles: [],
                              }
                    }
                    onSubmit={handleSubmit}
                />

                {isPassed && (
                    <div>
                        <Typography variant="body2" color="error.main">
                            {t(
                                'EVENT_PASSED_ALREADY',
                                'This event already started or has passed and you can no longer edit it',
                            )}
                        </Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions sx={{ padding: '20px' }}>
                <Button onClick={onClose} disabled={isLoading}>
                    {t('TEXT_CANCEL', 'AVBRYT')}
                </Button>
                <StyledContainedButton
                    disableElevation
                    variant="contained"
                    color="secondary"
                    type="submit"
                    form={FORM_ID}
                    disabled={isLoading || isPassed}
                >
                    {isLoading ? <CircularProgress /> : t('CREATE_NOTIFICATION_SAVE_BTN', 'Publiser statusmelding')}
                </StyledContainedButton>
            </DialogActions>
        </Dialog>
    )
}
