/** @jsxImportSource @emotion/react */
import React from 'react'
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@mui/material'
import { cubitFormStyles } from './cubit-form-styles'

const CubitCheckboxAdapter: React.FC<any> = (props: any) => {
    const { label, ...rest } = props

    const checked = props.input.multiple
        ? props.input.value.includes(rest.options[0]?.value)
        : props.input.value === '1' || props.input.value === true

    return (
        <FormControl css={cubitFormStyles.field}>
            <FormGroup>
                <FormControlLabel control={<Checkbox {...props.input} {...rest} checked={checked} />} label={label} />
            </FormGroup>
        </FormControl>
    )
}
export { CubitCheckboxAdapter }
