import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Stack, styled } from '@mui/material'
import dayjs from 'dayjs'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCreateAccessTokenMutation } from 'redux/apis/access-tokens-api'
import { CreateAccessTokenRequest } from 'types/access-tokens/access-token-requests'
import { useForm } from 'react-hook-form'
import { ControlledMuiTextField } from 'components/general/forms/rhf/controlled-mui-textfield'
import { ControlledMultiCheckboxField } from 'components/general/forms/rhf/controlled-mui-multi-checkbox'
import { ControlledMuiSelect } from 'components/general/forms/rhf/controlled-mui-select'
import { useGetCompanyQuery } from 'redux/apis/companies-api'
import { LoadingButton } from '@mui/lab'
import { TOKEN_AGE_OPTIONS } from 'constants/company-config'
import ModalsWrapper from 'components/general/dialogs/dialog-wrapper'
import { createTokenForm } from 'utils/validators/access-token'

const FORM_ID = 'create-token-form'

type CreateTokenDialogProps = {
    isOpen: boolean
    onTokenCreate: (createdTokenkey: string) => void
    onClose: () => void
    companyId: string
}

export const CreateTokenDialog: React.FC<CreateTokenDialogProps> = ({ isOpen, onClose, onTokenCreate, companyId }) => {
    const { t } = useTranslation()

    const [create, { isLoading }] = useCreateAccessTokenMutation()
    const { data: company, isLoading: isLoadingCompany } = useGetCompanyQuery(companyId)

    const { handleSubmit, control } = useForm<CreateAccessTokenRequest>({
        defaultValues: {
            name: '',
            scopes: [],
            validUntil: TOKEN_AGE_OPTIONS[0],
        },
        shouldFocusError: true,
        mode: 'onSubmit',
        shouldUnregister: true,
        resolver: zodResolver(createTokenForm),
    })

    const onSubmit = async (values: CreateAccessTokenRequest) => {
        try {
            const request = { ...values }
            const expirationDaysNumber = Number(values.validUntil)
            const isExpirationNever = isNaN(expirationDaysNumber)
            const currentDate = dayjs()

            request.validUntil = isExpirationNever
                ? currentDate.set('year', 3000).utc().format()
                : currentDate.add(expirationDaysNumber, 'days').utc().format()

            const response = await create(request).unwrap()

            onTokenCreate(response.key)
        } catch (err) {
            console.error(err)
        }
    }

    if (isLoadingCompany || !company) return null

    return (
        <ModalsWrapper
            open={isOpen}
            title={t('CREATE_ACCESS_TOKEN_DIALOG_TITLE', 'Create access token')}
            handleClose={onClose}
            fullWidth={true}
            maxWidth="sm"
            body={
                <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
                    <ControlledMuiTextField
                        control={control}
                        name="name"
                        placeholder={t('name', 'Name')}
                        variant="outlined"
                        fullWidth
                    />

                    <ControlledMultiCheckboxField
                        control={control}
                        name="scopes"
                        options={company.config.apiAccess.availableScopes.map((scope) => ({
                            label: scope,
                            value: scope,
                        }))}
                    />

                    <ControlledMuiSelect
                        control={control}
                        name="validUntil"
                        options={TOKEN_AGE_OPTIONS.map((opt) => ({
                            // t('ACCESS_TOKEN_AGE_OPT_7')
                            // t('ACCESS_TOKEN_AGE_OPT_30')
                            // t('ACCESS_TOKEN_AGE_OPT_60')
                            // t('ACCESS_TOKEN_AGE_OPT_90')
                            // t('ACCESS_TOKEN_AGE_OPT_Never')
                            label: t(`ACCESS_TOKEN_AGE_OPT_${opt}`, opt),
                            value: opt,
                        }))}
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                </form>
            }
            actions={
                <ActionWrapper>
                    <Button onClick={onClose} disabled={isLoading}>
                        {t('TEXT_CANCEL', 'AVBRYT')}
                    </Button>
                    <LoadingButton
                        disableElevation
                        variant="contained"
                        color="accent"
                        type="submit"
                        form={FORM_ID}
                        loading={isLoading}
                    >
                        {t('CREATE_ACCESS_TOKEN', 'Create token')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

const ActionWrapper = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))
