import React from 'react'
import { getStandardRightActions, TopBar } from '../topbar/top-bar'
import { useNavigate, useParams } from 'react-router-dom'
import { KotiContainer } from '../general/layout/koti-container'
import { Grid } from '@mui/material'
import { KotiBackButton } from '../general/buttons/back-button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useTranslation } from 'react-i18next'
import { CurrentPeriodContent } from './components/current-period-line'
import { CurrentPeriodOrdersTable } from './components/current-period-orders-table'

export const SingleBillingPage: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { companyId } = useParams()
    // const { data: invoice } = useGetInvoiceQuery({ companyId: companyId ?? '', invoiceId: id ?? '' })
    if (!companyId) return null

    return (
        <div>
            <TopBar rightActions={getStandardRightActions()} />
            <KotiContainer>
                <Grid item xs={12}>
                    <KotiBackButton
                        startIcon={<ArrowBackIcon />}
                        text={t('invoice_overview', 'Til fakturaoversikt')}
                        onClick={() => navigate(-1)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CurrentPeriodContent companyId={companyId} />
                </Grid>
                <Grid item xs={12}>
                    <CurrentPeriodOrdersTable companyId={companyId} />
                </Grid>
            </KotiContainer>
        </div>
    )
}
