import { KotiTable } from '../../general/table/koti-table'
import TablePagination from '@mui/material/TablePagination'
import React, { useEffect, useState } from 'react'
import { useGetCompanyDepartmentsUsersQuery } from '../../../redux/apis/companies-api'
import { useLazyGetCompanyOrdersQuery } from '../../orders/orders.service'
import { useTranslation } from 'react-i18next'
import { SortDirection } from '../../general/models/sort-direction'
import { useAppSelector } from '../../../redux/hooks'
import { OrderFilters, selectOrdersFilters } from '../../orders/order.slice'
import { usePrevious } from '../../general/use-previous'
import { PaginationParams } from '../../general/models/pagination-params'
import { Order, OrderTableItem } from '../../orders/models/order'
import { getProductList } from '../../orders/orders-helper'
import { IconButton, LinearProgress, Paper } from '@mui/material'
import { TFunction } from 'i18next'
import { User } from '../../users/user'
import { TableStructure } from '../../general/table/table-structure.type'
import { formatDateTime } from '../../../utils/date-utils'
import { MultiStringViewer } from '../../general/multi-viewer/multi-string-viewer'
import { Download } from '@mui/icons-material'
import { Config } from '../../../redux/config'

type Props = {
    companyId: string
}
const onDownload = (object: OrderTableItem) => {
    const link = document.createElement('a')
    link.href = `${Config.backendUrl}/v1/orders/${object.orderId}/pdf/${object.index}?accessToken=${object.pdf.accessToken}`
    link.click()
}
const getOrdersTableStructure = (t: TFunction, users?: User[]): TableStructure<OrderTableItem>[] => [
    {
        label: t('TEXT_DATE', 'Dato'),
        columnName: 'created',
        sortFunction: (object) => object.created.getTime(),
        value: (object) => formatDateTime(object.created),
    },
    {
        label: t('TEXT_PROJECT_NR', 'Oppdragsnr.'),
        value: (object) => object.note,
    },
    {
        label: t('TEXT_ORDER_NR', 'Ordrenr.'),
        columnName: 'orderId',
        sortFunction: (object) => object.orderNumber,
        value: (object) => object.orderNumber,
    },
    {
        label: t('TEXT_ORDER_BY', 'Bestilt av'),
        columnName: 'userId',
        sortFunction: (object) => object.userId,
        value: (object) => {
            if (object.apiKeyMetadata?.email) return object.apiKeyMetadata.email
            if (users) {
                const user = users.find((u) => u.id === object.userId)
                return user?.name
            }
            return '-'
        },
    },
    {
        label: t('TEXT_ADDRESS', 'Adresse'),
        sortFunction: (object) => (object.address ? object.address.addressText : ''),
        value: (object) =>
            object.address ? <MultiStringViewer items={object.address.addressText.split(',')} owners={false} /> : '',
    },
    {
        label: t('TEXT_POStAL_CODE', 'Postnummer'),
        sortFunction: (object) => object.address.postalCode,
        value: (object) => object.address && `${object.address.postalCode || ''} ${object.address.postalName || ''}`,
    },
    {
        label: t('TEXT_CADASTRE_NR', 'Matrikkelnr.'),
        sortFunction: (object) => object.cadastreNr,
        value: (object) => object.cadastreNr,
    },
    {
        label: t('TEXT_DOWNLOAD', 'Last ned'),
        value: (object) => {
            if (object.pdf !== null) {
                return (
                    <IconButton
                        name={'download'}
                        onClick={(e) => {
                            onDownload(object)
                            e.stopPropagation()
                        }}
                    >
                        <Download aria-label={t('TEXT_DOWNLOAD', 'Last ned')} />
                    </IconButton>
                )
            }
        },
    },
]

export const CurrentPeriodOrdersTable: React.FC<Props> = ({ companyId }) => {
    const { t } = useTranslation()
    const [pageNumber, setPageNumber] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [sortBy, setSortBy] = useState('created')
    const [direction, setDirection] = useState<SortDirection>('desc')
    //  const context = useAppSelector(selectAuthContext)
    const filters = useAppSelector(selectOrdersFilters)
    const prevFilters = usePrevious(filters)
    const { data: users } = useGetCompanyDepartmentsUsersQuery({
        page: 0,
        itemsPerPage: 9999,
        sortBy: 'id',
        direction: 'asc',
        companyId: companyId ?? '',
    })

    const [trigger, { data }] = useLazyGetCompanyOrdersQuery()
    const pagination: PaginationParams<Order, OrderFilters> = {
        page: pageNumber,
        itemsPerPage: itemsPerPage,
        sortBy: sortBy,
        direction: direction,
        ...filters,
        invoiced: false,
    }
    const params = { pagination, companyId }
    const onSubmit = () => trigger(params)
    const handleOnSortChange = (orderBy: string, order: SortDirection) => {
        setSortBy(orderBy)
        setDirection(order)
    }
    useEffect(() => {
        if (prevFilters === filters) {
            onSubmit()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, itemsPerPage, direction, trigger, sortBy, filters, prevFilters])
    useEffect(() => {
        onSubmit()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!data || !data.data) {
        return <LinearProgress />
    }
    const products = getProductList(data.data)

    return (
        <Paper elevation={0} square>
            <KotiTable
                selectable={false}
                pagination={false}
                rowsPerPageOptions={[]}
                tableStructure={getOrdersTableStructure(t, users?.data)}
                rowsPerPage={products.length}
                page={pageNumber}
                rows={products}
                total={products.length}
                onSortChanged={handleOnSortChange}
                handleChangePage={setPageNumber}
                handleChangeRowsPerPage={setItemsPerPage}
            />
            <TablePagination
                labelRowsPerPage={<span>{t('ROWS_PER_PAGE', 'Rader per side')}</span>}
                component="div"
                count={data.total}
                rowsPerPage={itemsPerPage}
                page={pageNumber}
                onPageChange={(event, newPage) => setPageNumber(newPage)}
                onRowsPerPageChange={(event: any) => setItemsPerPage(event.target.value)}
            />
        </Paper>
    )
}
